import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { removeClinicCredential, removeProviderCredential } from "../../app/actions";
import { useDispatch } from "react-redux";

import AppliedTags from "../minimal/AppliedTags";
import Modal from "../modal/Modal";
import { toast } from "../notifications/Toast";
import CredentialsSelector from "../credentials_selector/CredentialsSelector";

export default function EditCredentialsModal({ show, credentials, handleClose, clinicId = null, providerId = null }) {
    const dispatch = useDispatch();
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        if (credentials) setSelectedTypes(credentials.map((c) => c.credential));
    }, [show]); //eslint-disable-line

    // const handleTypesChange = async (event) => {
    //     let result = null;
    //     if (clinicId) {
    //         result = await dispatch(createClinicCredential({ clinic: clinicId, credential: event.target.value }));
    //     } else if (providerId) {
    //         result = await dispatch(createProviderCredential({ profile: providerId, credential: event.target.value }));
    //     }

    //     const { error } = result;

    //     if (error) {
    //         toast.error("Error adding credential");
    //     } else {
    //         const updatedTypes = [...selectedTypes, event.target.value];
    //         document.getElementById("types").blur();
    //         setSelectedTypes(updatedTypes);
    //     }
    // };

    const handleRemoveSelectedType = async (type) => {
        const currentType = credentials.find((c) => c.credential === type);
        let result = null;
        if (clinicId) {
            result = await dispatch(removeClinicCredential(currentType.id));
        } else if (providerId) {
            result = await dispatch(removeProviderCredential(currentType.id));
        }

        const { error } = result;

        if (error) {
            toast.error("Error removing credential");
        } else {
            const updatedTypes = selectedTypes.filter((t) => t !== type);
            setSelectedTypes(updatedTypes);
        }
    };

    const updateSelectedCredentials = (newCred) => {
        const updatedTypes = [...selectedTypes, newCred];
        setSelectedTypes(updatedTypes);
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            Edit Credentials
                        </Typography>
                        <CredentialsSelector
                            providerId={providerId}
                            credentials={credentials}
                            updateSelectedCredentials={updateSelectedCredentials}
                        />
                        {/* <TextField
                            id="types"
                            select
                            placeholder="Select Credentials"
                            label="Select Credentials"
                            value=""
                            onChange={handleTypesChange}
                            disabled={selectedTypes && selectedTypes.length === 6}
                            sx={{ width: "100%" }}
                        >
                            {PROVIDER_TYPES.filter((type) => !selectedTypes.includes(type.label)).map((type, idx) => (
                                <MenuItem key={idx} value={type.label}>
                                    <ListItemText primary={type.label} />
                                </MenuItem>
                            ))}
                        </TextField> */}
                        <Typography variant="overline" sx={{ px: 2 }}>
                            Current credentials
                        </Typography>
                        <AppliedTags onRemoveTag={handleRemoveSelectedType} tags={selectedTypes} />

                        <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                className="text-right"
                                onClick={handleClose}
                            >
                                Done
                            </LoadingButton>
                        </Stack>
                    </div>
                }
            />
        </>
    );
}
