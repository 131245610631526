import React, { useEffect, useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";

import { updateClinicProfile, updateProviderProfile } from "../../app/actions";
import { useForm } from "react-hook-form";

import ButtonLink from "../button/ButtonLink";
import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import { toast } from "../notifications/Toast";

const initialValues = {
    bio: "",
};

export default function EditBioModal({ show, clinic = null, provider = null, handleClose }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [bio, setBio] = useState("");
    const methods = useForm({
        initialValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset({
            ...initialValues,
            bio: clinic ? clinic.bio : provider ? provider.bio : "",
        });
        setBio(clinic ? clinic.bio : provider ? provider.bio : "");
    }, [show]); // eslint-disable-line

    const handleBioChange = (event) => {
        const {
            target: { value },
        } = event;

        setBio(value);
    };

    const handleFormSubmit = async () => {
        let result = null;
        if (bio && bio.length > 1000) {
            return;
        }

        const body = {
            bio,
        };

        if (clinic) {
            result = await dispatch(updateClinicProfile(clinic.id, body));
        } else if (provider) {
            result = await dispatch(updateProviderProfile(provider.id, body));
        }

        if (result.error) {
            toast.error("Error updating bio");
        } else {
            toast.success("Bio updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Edit Bio
                            </Typography>
                            <RHFTextField
                                name="bio"
                                label="Bio"
                                value={bio}
                                onChange={handleBioChange}
                                multiline
                                minRows={4}
                                maxRows={8}
                                sx={{ mb: 2 }}
                            />
                            <Stack sx={{ flexDirection: "row", justifyContent: "end" }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color:
                                            bio && bio.length > 1000
                                                ? theme.palette.error.main
                                                : theme.palette.common.black,
                                    }}
                                >
                                    Character limit {bio && bio.length}/1000
                                </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <ButtonLink link onClick={handleClose}>
                                    Cancel
                                </ButtonLink>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    className="text-right"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
