import React, { useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";

import { updateCoach, updateProviderProfile } from "../../app/actions";
import { REQUIRED_FIELD } from "../../utils/validations";
import { useForm } from "react-hook-form";

import ButtonLink from "../button/ButtonLink";
import FormProvider from "../minimal/hook-form/FormProvider";
import Modal from "../modal/Modal";
import RHFTextField from "../minimal/hook-form/RHFTextField";
import { toast } from "../notifications/Toast";

const providerSchema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_FIELD.message),
    last_name: yup.string().required(REQUIRED_FIELD.message),
});

const initialValues = {
    first_name: "",
    last_name: "",
    title: "",
};

export default function EditProviderInfoModal({ show, provider, handleClose }) {
    const dispatch = useDispatch();
    const methods = useForm({
        resolver: yupResolver(providerSchema),
        initialValues,
    });

    const {
        getValues,
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset({
            ...initialValues,
            first_name: provider && provider.user && provider.user.first_name,
            last_name: provider && provider.user && provider.user.last_name,
            title: provider && provider.title,
        });
    }, [show]); // eslint-disable-line

    const handleFormSubmit = async () => {
        const { first_name, last_name, title } = getValues();
        const body = {
            first_name,
            last_name,
        };

        const result = await dispatch(updateCoach(provider.user.id, body));
        const titleResult = await dispatch(updateProviderProfile(provider.id, { title }));

        if (result.error || titleResult.error) {
            toast.error("Error updating profile");
        } else {
            toast.success("Profile updated");
            handleClose();
        }
    };

    return (
        <>
            <Modal
                show={show}
                toggleShow={handleClose}
                content={
                    <div>
                        <Typography variant="h6" sx={{ my: 2 }}>
                            Edit Provider Info
                        </Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="first_name" label="First Name" />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <RHFTextField name="last_name" label="Last Name" />
                                </Grid>
                                <Grid item xs={12}>
                                    <RHFTextField name="title" label="Title" />
                                </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="end" spacing={2} sx={{ my: 2 }}>
                                <ButtonLink link onClick={handleClose} className="p-2">
                                    Cancel
                                </ButtonLink>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    className="text-right"
                                >
                                    Save
                                </LoadingButton>
                            </Stack>
                        </FormProvider>
                    </div>
                }
            />
        </>
    );
}
